<template>
  <div class="past-time-debtors-report fixed-draggable-dynamic-table-wrapper-height top-zero-radius">
    <draggable-dynamic-table ref="pastTimeDebtorsList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @filter:remove="setFilter($event)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getReportsDetail()"/>

    <button id="printDebtorUserReport" v-show="false" @click="handlePrintReport"></button>
  </div>
</template>

<script>
  import {addComma, getTimeFromServer, hideLoading, showLoading} from "../../../../../assets/js/functions";
  import InsertReport from "../insert/insertReport";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
import {getReportDetail, printReportDetail} from "../../../../../http/requests/reports";
import axios from "axios";

  export default {
    name: 'pastTimeDebtorsReport',
    components: {CustomIcon, InsertReport},
    metaInfo() {
      return {
        title: this.report && this.report.id ? `گزارش بدهکاران شماره ${this.report.id}` : 'گزارش بدهکاران'
      }
    },
    data () {
      return {
        report: {},
        insertNewReportPromptStatus: false,
        loadingTimer: 0,
        options: {
          id: 'pastTimeDebtorsList',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'debt',
            i18n: 'reports.pastTimeDebtor.table.header.debt',
            width: 'calc(100% / 2)',
            minWidth: 170,
            align: 'center',
            sortable: true,
            filter: true,
            filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 3
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 4
            }
            ],
            locked: true
          },
          {
            field: 'phoneNumber',
            i18n: 'reports.pastTimeDebtor.table.header.phoneNumber',
            width: 'calc(100% / 2)',
            minWidth: 170,
            align: 'center',
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ],
            locked: true
          },
          {
            field: 'userName',
            i18n: 'reports.pastTimeDebtor.table.header.userName',
            width: 'calc(100% / 2)',
            minWidth: 170,
            align: 'center',
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ],
            locked: true
          },
          {
            field: 'rowNumber',
            i18n: 'reports.pastTimeDebtor.table.header.rowNumber',
            width: '60px',
            minWidth: 60,
            align: 'center',
            // sortable: true,
            locked: true,
            footer: {}
          }
        ],
        filters: [],
        sorts: ['order[0]=debt,desc'],
        data: [],
        page: 1,
        endedList: false,
        actions: [
          {
            toolbar: [],
            leftToolbar: [
              // {
              //   id: 'printDebtorUserReport',
              //   // i18n: 'draggableDynamicTable.actions.print',
              //   icon: 'PRINT',
              //   iconPack: 'useral'
              // },
              // {
              //   id: 'downloadTable',
              //   // i18n: 'draggableDynamicTable.actions.download',
              //   icon: 'DOWNLOAD',
              //   iconPack: 'useral'
              // },
              // {
              //   id: 'settingTable',
              //   icon: 'icon-settings',
              //   iconPack: 'feather'
              // },
              {
                id: {name: 'advanceReport'},
                type: 'link',
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created () {
      // this.$vs.loading()
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getReportsDetail()
    },
    methods: {
      addComma (val) {
        let sign = ''

        if (val < 0) sign = '-'

        val = val.toString()

        if (val[0] === '0') val = val.substr(1, val.length - 1)

        return val.toString().replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
      },
      getReportsDetail () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.pastTimeDebtorsList && this.data.length === 0) this.$refs.pastTimeDebtorsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.pastTimeDebtorsList && this.data.length > 0) this.$refs.pastTimeDebtorsList.loadMoreStatus = 'Loading'

            getReportDetail(this.$route.params.id, this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const reports = response.data.data
              reports.forEach((report) => {
                this.data.push({
                  rowNumber: this.data.length + 1,
                  id: report.id,
                  userName: report.user ? `${report.user.name || ''} ${report.user.family || ''}` : '',
                  phoneNumber: {
                    value: report.user ? report.user.phone_number : '',
                    classes: this.$vs.rtl ? 'rtl' : 'ltr'
                  },
                  debt: {
                    type: 'price',
                    // classes: report.balance && report.balance < 0 ? 'text-danger' : 'text-success',
                    value: report.debt ? report.debt : 0
                  }
                })
              })
              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.pastTimeDebtorsList) this.$refs.pastTimeDebtorsList.loadMoreStatus = ''

              this.report = response.data.report
              setTimeout(() => {
                this.$store.dispatch('setPageTitle', `گزارش بدهکاران شماره ${this.report.id}`)
              }, 50)
            }).catch(error => {
              if (this.$refs.pastTimeDebtorsList && !axios.isCancel(error)) this.$refs.pastTimeDebtorsList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      setFilter (filters) {
        let filters_list = []
        const attributes = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'id':
              if (filters[key].search !== '') filters_list.push(`id=${filters[key].search}`)
              break

            case 'userName':
              if (filters[key].search !== '') filters_list.push(`fullName=${filters[key].search}`)
              break

            case 'phoneNumber':
              if (filters[key].search !== '') filters_list.push(`phoneNumber=${filters[key].search}`)
              break

            case 'debt':
              if (filters[key].search !== '') filters_list.push(`debt=${filters[key].search},${  filters[key].type.id}`)
              break
          }
        })
        filters_list = [filters_list, ...attributes]

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getReportsDetail()
      },
      setSort (sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {

            case 'id':
              sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'userName':
              sorts_list.push(`order[0]=full_name,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'phoneNumber':
              sorts_list.push(`order[0]=phone_number,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'debt':
              sorts_list.push(`order[0]=debt,${  sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) sorts_list.push('order[0]=debt,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getReportsDetail()
      },
      handleClick (id) {
        document.getElementById(id).click()
      },
      handlePrintReport () {
        showLoading()

        printReportDetail(this.$route.params.id, this.filters, this.sorts).then(response => {
          hideLoading()

          const data = response.data

          let time = getTimeFromServer()
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `debtor-user-report-${this.report.id}-${ time }.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
            .catch((error) => {
              hideLoading()

              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: 'هشدار',
                  text: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  title: 'خطا',
                  text: 'درخواست پرینت با خطا شد',
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              }
            })
      }
    }
  }
</script>

<style scoped>

</style>
