<template>
  <div class="daily-sales-report-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table v-if="columnsLabel.length > 3"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilter($event)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getReportDetail()"/>


    <button id="printDailySalesReport" v-show="false" @click="handlePrintReport"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {checkUserPermissions, getTimeFromServer, hideLoading, showLoading} from "../../../../../assets/js/functions";
  import {getSaleAddons} from "../../../../../http/requests/club/salesAddons";
  import {getReportDetail, printReportDetail} from "../../../../../http/requests/reports";
  export default {
    name: "dailySalesReport",
    metaInfo() {
      return {
        title: this.report && this.report.id ? `گزارش فروش روزانه شماره ${this.report.id}` : 'گزارش فروش روزانه'
      }
    },
    data () {
      return {
        report: {},
        selectedRow: {},
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'timeSale',
            headerName: 'فروش تایم',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 120,
            // sortable: true,
            // filter: true,
            // filterTypes: [
            //   {
            //     icon: 'search',
            //     name: 'شامل شود',
            //     i18n: 'draggableTable.filter.types.search',
            //     id: 1
            //   }
            // ]
          },
          {
            field: 'date',
            headerName: 'تاریخ',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 150,
            // sortable: true,
            // filter: true,
            // filterType: 'date'
          },
          {
            field: 'rowNumber',
            i18n: 'reports.pastTimeDebtor.table.header.rowNumber',
            width: '60px',
            minWidth: 60,
            align: 'center',
            // sortable: true,
            locked: true,
            // footer: {}
          }
        ],
        actions: [
          {
            toolbar: [],
            leftToolbar: [
              // {
              //   id: 'printDailySalesReport',
              //   // i18n: 'draggableDynamicTable.actions.print',
              //   icon: 'PRINT',
              //   iconPack: 'useral'
              // },
              // {
              //   id: 'downloadTable',
              //   // i18n: 'draggableDynamicTable.actions.download',
              //   icon: 'DOWNLOAD',
              //   iconPack: 'useral'
              // },
              // {
              //   id: 'settingTable',
              //   icon: 'icon-settings',
              //   iconPack: 'feather'
              // },
              {
                id: {name: 'advanceReport'},
                type: 'link',
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created () {
      setTimeout(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getSaleAddons()
      this.getReportDetail()
    },
    mounted () {
      setTimeout(() => {
        showLoading()
      }, 480)
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getReportDetail () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.dailySalesReport && this.data.length === 0) this.$refs.dailySalesReport.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.dailySalesReport && this.data.length > 0) this.$refs.dailySalesReport.loadMoreStatus = 'Loading'

            getReportDetail(this.$route.params.id, this.page, this.filters, []).then((response) => {
              const reports = response.data
              let data = []
              reports.data.forEach((report) => {

                if (!data[`date_${report.date}`]) {
                  data[`date_${report.date}`] = []
                }
                data[`date_${report.date}`]['date'] = report.date
                data[`date_${report.date}`]['rowNumber'] = Object.values(data).length
                switch (report.type) {
                  case 1:
                    data[`date_${report.date}`]['total'] = {
                      value: report.total,
                      type: 'price',
                      currency: this.$locale.currency()
                    }
                    break

                  case 2:
                    data[`date_${report.date}`]['timeSale'] = {
                      value: report.total,
                      type: 'price',
                      currency: this.$locale.currency()
                    }
                    break

                  case 3:
                    data[`date_${report.date}`][`addon_${report.sale_addon.id}`] = {
                      value: report.total > 0 ? report.total : '',
                      type: 'price',
                      currency: this.$locale.currency()
                    }
                    break
                }
              })
              this.data = Object.values(data)

              this.total_count = reports.pagination.total
              this.page = reports.pagination.current_page + 1

              // if (response.data.pagination.current_page === 1) {
              //   const row_index = this.columnsLabel.map((e) => {
              //     return e.field
              //   }).indexOf('rowNumber')
              //   if (row_index > -1) {
              //     this.columnsLabel[row_index].footer.value = response.data.pagination.total
              //   }
              // }

              if (this.$refs.dailySalesReport) this.$refs.dailySalesReport.loadMoreStatus = ''
              this.report = response.data.report
              setTimeout(() => {
                this.$store.dispatch('setPageTitle', `گزارش فروش روزانه شماره ${this.report.id}`)
              }, 50)
            }).catch((error) => {
              if (this.$refs.dailySalesReport && !axios.isCancel(error)) this.$refs.dailySalesReport.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      getSaleAddons () {
        getSaleAddons().then(response => {
          const addons = response.data.data
          let columnsLabel = JSON.parse(JSON.stringify(this.columnsLabel))
          addons.forEach(addon => {
            columnsLabel.unshift(
              {
                field: `addon_${addon.id}`,
                headerName: addon.name,
                width: 'calc(100% / 10)',
                minWidth: 120,
              })
          })

          columnsLabel.unshift(
            {
              field: 'total',
              headerName: 'جمع کل',
              width: 'calc(100% / 10)',
              minWidth: 120,
            })
          this.columnsLabel = []
          this.columnsLabel = columnsLabel
          hideLoading()
        })
          .catch(error => {
            hideLoading()
          })
      },
      handlePrintReport () {
        showLoading()

        printReportDetail(this.$route.params.id, this.filters, this.sorts).then(response => {
          hideLoading()

          const data = response.data

          let time = getTimeFromServer()
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `daily-sales-report-${this.report.id}-${ time }.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
          .catch((error) => {
            hideLoading()

            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: 'هشدار',
                text: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: 'خطا',
                text: 'درخواست پرینت با خطا شد',
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
      },
      setFilter (filters) {
        let filters_list = []
        const attributes = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'id':
              if (filters[key].search !== '') filters_list.push(`id=${filters[key].search}`)
              break

            case 'userName':
              if (filters[key].search !== '') filters_list.push(`fullName=${filters[key].search}`)
              break

            case 'phoneNumber':
              if (filters[key].search !== '') filters_list.push(`phoneNumber=${filters[key].search}`)
              break

            case 'debt':
              if (filters[key].search !== '') filters_list.push(`debt=${filters[key].search},${  filters[key].type.id}`)
              break
          }
        })
        filters_list = [filters_list, ...attributes]

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getReportDetail()
      },
      setSort (sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {

            case 'id':
              sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'userName':
              sorts_list.push(`order[0]=full_name,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'phoneNumber':
              sorts_list.push(`order[0]=phone_number,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'debt':
              sorts_list.push(`order[0]=debt,${  sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) sorts_list.push('order[0]=debt,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getReportDetail()
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>

</style>
